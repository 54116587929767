@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Your custom styles here */

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('~inter-font/ttf/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700; 
  src: url('~inter-font/ttf/Inter-ExtraBold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Poppins, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.btn-loader-span {
  height: 15px;
  width: 15px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 